import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useState, useCallback } from 'react'

import { getEnv } from '../../../utils/getEnv'

export function AdobeTag() {

  const [env, setEnv] = useState<'dev' | 'staging' | 'live' | null>(null)
  const [cookiesAccepted, setCookiesAccepted] = useState<undefined | boolean>(undefined)
  const [check, setCheck] = useState(0)

  const fetchCookies = useCallback(async () => {
    fetch('/xxx').then((res) => {
      res.json().then((data) => {
        // If this cookie exists, consent has already been given.
        const hasConsentBeenGiven = data.OptanonAlertBoxClosed !== undefined

        // Check if OptanonConsent cookie exists.
        if(data.OptanonConsent) {
          let optanonConsentCookie = data.OptanonConsent;
          const cookieAccepted = optanonConsentCookie.includes('C0004:1')
          const cookieNotAccepted = optanonConsentCookie.includes('C0004:0')
          console.log(data)
          // If cookie has already been accepted, fire adobe script.
          if (cookieAccepted) {
            setCookiesAccepted(true)
            console.log('Optanon cookies contains C0004:1');
          } else if(optanonConsentCookie.includes('landingPath=https://www.universalorlando.com')) {
            // If the user is coming from US universal orlando site, check the window for active groups.
            // These are set once the user interacts with the cookie preferences message.
            console.log('Optanon cookie is coming from universalorlando.com')

            if(window.OnetrustActiveGroups !== undefined) {
              console.log('One trust groups active.')
              const OTActiveGroups = window.OnetrustActiveGroups;
              // If the user accepts, the C0004 group will appear.
              if (OTActiveGroups.includes('C0004')){
                setCookiesAccepted(true)

                // We need to append these groups to the cookie.
                // This means if a user returns to this page, they wont have to give consent again.
                const newCookieString = cookieConsentStringAddGroups(OTActiveGroups, optanonConsentCookie, ':1');
                document.cookie = newCookieString
                console.log('new cookie activated and consent accepted')
              } else if (hasConsentBeenGiven && !OTActiveGroups.includes('C0004')){
                setCookiesAccepted(false)
                // We need to append these groups to the cookie.
                // This means if a user returns to this page, they wont have to give consent again.
                const newCookieString = cookieConsentStringAddGroups(',C0001,C0004,', optanonConsentCookie, ':0');
                document.cookie = newCookieString
                console.log('new cookie activated but consent declined')
              } else {
                console.log('waiting for access from universal US site to give access.')
              }
              // If this cookie shows and the above requirements not met - cookies not accepted.
            } else if (hasConsentBeenGiven) {
              setCookiesAccepted(false)
              console.log('new cookie declined.')
            }
          } else if (hasConsentBeenGiven && cookieNotAccepted) {
            setCookiesAccepted(false);
            console.log('cookies declined')
          }
        }
        else {
          console.log("OptanonConsent cookie does not exist.")
          // setCookiesAccepted(false);
        }
      })
    })
  },[])

  const cookieConsentStringAddGroups = (groups: string, cookieString: string, consentGiven: string): string => {
    // Extract the consent groups.
    let groupsConsent = '';
    groups.split(',').forEach((group) => {
      if(group !== '') {
        groupsConsent += group + consentGiven
      }
    })


    // Add the groups to the universalorlando landing path cookie.
    // This will mean that if a user returns to the page, the consent is accepted.
    const groupsString = "&groups="
    const groupsStart = cookieString.lastIndexOf(groupsString)

    let newCookie = ''
    if(groupsStart !== -1) {
      const groupsEnd = groupsStart + groupsString.length
      // Encode these in preparation for adding to cookie string.
      newCookie = encodeURIComponent("OptanonConsent="+ cookieString.slice(0,  groupsEnd) + groupsConsent + cookieString.slice(groupsEnd));
    }
    return newCookie
  }

  useEffect(() => {
    setEnv(getEnv())
    if (cookiesAccepted === undefined) {
      const id = setInterval(() => {
        fetchCookies()
        console.log('fetching cookies....')
        setCheck(check + 1)
      }, 3000)
      return () => clearInterval(id)
    }
  }, [check, cookiesAccepted, fetchCookies])

  if (env === 'staging' && cookiesAccepted) {
    return <Script src="https://assets.adobedtm.com/a2ef59fba8e9/7f6d378ecd1a/launch-bbc1affd862a-staging.min.js" async />
  } else if (env === 'live' && cookiesAccepted) {
    return <Script src="https://assets.adobedtm.com/a2ef59fba8e9/7f6d378ecd1a/launch-2ef5cb9792e7.min.js" async />
  } else if (env === 'dev' && cookiesAccepted) {
    return <Script src="https://assets.adobedtm.com/a2ef59fba8e9/7f6d378ecd1a/launch-bbc1affd862a-staging.min.js" async />
  }

  return null

}
